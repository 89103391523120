import React, { useState } from "react";
import previewImage  from "../../../../assets/images/dashboard/menu/preview2.png";


function PreviewScreen() {
  return (
		<>
		<div className="preview-area text-center">
		<h5>Preview</h5>
        <img src={previewImage} className="preview-image"/>  
		</div>
		</>
  );
}

export default PreviewScreen;
