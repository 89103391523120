function Dheader() {
    return (<>
        <div className="row m-1 pb-3">
            <div className="col-md-6">
                <h2 className="heading">My Account</h2>
            </div>
            <div className="col-md-6 text-right">
                <button className="btn btn-info mr-3">Cancel</button>
                <button className="btn btn-primary">Save</button>
            </div>
        </div>
    </>)
}

export default Dheader;